import React, { FC, useEffect } from "react";
import { Button, Drawer, message, Result } from "antd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import LayoutStore from "../../contexts/layoutStore";
import consts from "../../utilities/consts";
import ChecklistAdminHeader from "./checklistAdminHeader";
import { useApi } from "../../services/useApi";
import { ChecklistGroupType } from "../../types/checklistTypes";
import { checklistUrl, loadDefaultChecklist } from "../../services/checklistService";
import ChecklistAdminGroups from "./checklistAdminGroups";
import ChecklistGroupSort from "./checklistGroupSort";
import EmptyChecklist from "./emptyChecklist";
import { isAxiosError } from "../../utilities/typeGuard";
import Icon from "../../assets/icons/icon";

const ChecklistAdminContent: FC = () => {
  const {
    hideTotal,
    groups,
    drawerVisibility,
    dispatchGroups,
    context,
    id,
    groupSortDrawer,
    closeGroupSortDrawer,
    openDrawer,
    closeDrawer,
    total,
    completedTotal,
    btnText,
    asDrawer
  } = ChecklistAdminStore.useContainer();
  const { screen } = LayoutStore.useContainer();

  const [{ data, isError, isLoading }, setUrl, setData] = useApi<{ data: ChecklistGroupType[] }>("", { data: [] });

  useEffect(() => {
    dispatchGroups({ type: "populate", payload: data.data });
  }, [data, dispatchGroups]);

  useEffect(() => {
    if (!!id || !!context) setUrl(checklistUrl(context, id));
  }, [id, context, setUrl]);

  const handleLoadDefaultChecklist = async () => {
    try {
      const { data: nextData } = await loadDefaultChecklist(context, id);
      setData(nextData);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };
  const width = screen.isTablet ? consts.drawerWidthSm : consts.checklistDrawerWidth;

  return (
    <>
    {asDrawer ? (
      <Button loading={isLoading} onClick={openDrawer}>
        {`${!hideTotal && !isLoading ? `${completedTotal}/${total}` : ""} ${btnText}`}
      </Button>
    ) : (
      <Button loading={isLoading} onClick={openDrawer} style={{
        position: "fixed",
        right: 0,
        zIndex: 99,
        width: 40,
        height: 40,
        padding: 0,
        transform: drawerVisibility ? "translateX(100%)" : "translateX(0%)"
      }}>
        {!hideTotal && !isLoading ? (completedTotal+"/"+total) : ""}
      </Button>
    )}


      {(asDrawer || screen.isMobile) && (
        <div onClick={closeDrawer} style={{
          position: "fixed",
          zIndex: 998,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0,0,0,0.45)",
          opacity: drawerVisibility ? 100 : 0,
          transition: "200ms opacity ease",
          pointerEvents: drawerVisibility ? "auto" : "none",
        }}></div>
      )}

      <div
        style={{
          marginTop: (asDrawer || screen.isMobile) ? 0 : -24,
          marginLeft: (asDrawer || screen.isMobile) ? 0 : 24,
          background: "white",
          width: width,
          marginRight: (asDrawer || screen.isMobile) ? 0 : drawerVisibility ? -24 : -width,
          boxShadow: '0px 2px 3px rgba(149, 145, 174, 0.16)',
          transform: drawerVisibility ? "translateX(0px)" : `translateX(100%)`,
          transition: "200ms ease all",
          position: (asDrawer || screen.isMobile) ? "fixed" : "static",
          top: 0,
          right: 0,
          zIndex: 999,
          height: (asDrawer || screen.isMobile) ? "100vh" : "none",
          alignSelf: "stretch",
          flex: "none",
          display:"flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >
        <Button type="text" icon={<Icon name="close-outline" size="large" />} onClick={closeDrawer} style={{
          position: "absolute",
          left: 4,
          top: 18,
          zIndex: 99,
        }} />
        <ChecklistAdminHeader />

        <div style={{
          flexGrow: 1,
          display:"flex",
          flexDirection: "column",
          minHeight: 0,
          position:"relative"
        }}>
          <div style={{
            position: "absolute",
            height: "100%",
            flexGrow:1,
            overflowY: "auto",
            zIndex: 1,
          }}>
            <div style={{ padding: 16 }}>
              {isError && <Result status="error" />}
              {groups.length === 0 && !isLoading && <EmptyChecklist onLoadDefaultChecklist={handleLoadDefaultChecklist} />}
              <ChecklistAdminGroups />
              {
                <Drawer
                  placement="right"
                  onClose={closeGroupSortDrawer}
                  title={"Tilpas grouper"}
                  visible={groupSortDrawer}
                  width={400}
                >
                  {groupSortDrawer && <ChecklistGroupSort />}
                </Drawer>
              }
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default ChecklistAdminContent;
