import React, { FC } from "react";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import ChecklistUserStore from "../../contexts/checklistUserStore";
import { IdType, ProductType } from "../../types/appTypes";
import ChecklistAdminContent from "./checklistAdminContent";
import ChecklistUserContent from "./checklistUserContent";

export interface ChecklistProps {
  isAdmin: boolean;
  id?: IdType;
  context: ProductType;
  hideTotal?: boolean;
  btnText: string;
  asDrawer?:boolean
}

const Checklist: FC<ChecklistProps> = (props) => {
  return props.isAdmin ? (
    <ChecklistAdminStore.Provider initialState={props}>
      <ChecklistAdminContent />
    </ChecklistAdminStore.Provider>
  ) : (
    <ChecklistUserStore.Provider initialState={props}>
      <ChecklistUserContent />
    </ChecklistUserStore.Provider>
  );
};

export default Checklist;
